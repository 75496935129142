.onboard-section {
  background-color: #3e7b81;
  color: #fff;
}

.onboard-section ul {
  list-style: none;
  padding-left: 0;
}
li {
  position: relative;
  padding-left: 1.5em; /* space to preserve indentation on wrap */
}
li:before {
  content: ""; /* placeholder for the SVG */
  position: absolute;
  left: 0; /* place the SVG at the start of the padding */
  width: 1em;
  height: 1em;
  background: url("data:image/svg+xml;utf8,<?xml version='1.0' encoding='utf-8'?><svg width='18' height='18' viewBox='0 0 1792 1792' xmlns='http://www.w3.org/2000/svg' fill='%23BFBFBF'><path d='M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z'/></svg>")
    no-repeat;
}

.onboard-details {
  padding: 5rem 0rem 5rem 5rem;
}

@media (max-width: 768px) {
  .onboard-details {
    padding: 1rem;
  }
}

.onboard-details h3 {
  font-weight: 400;
  font-size: 2.4rem;
  text-align: left;
}

.onboard-details h5 {
  margin-left: 2rem;
  font-size: 1.4rem;
}

.thin-text {
  font-weight: 200;
}

.iphones {
  width: 100%;
}

.impact-subheading {
  font-weight: 300;
  font-size: 2rem;
  color: #4d585a;
  padding-bottom: 1.6rem;
}

.invest-for-impact-section p {
  color: #4d585a;
}

.icons-for-impact {
  font-size: 3rem;
}

.underline-heading {
  border-color: #12717b;
  border-width: 0.3rem;
  padding-bottom: 0.2rem;
  width: 10rem;
}

@media (min-width: 1900px) {
  .onboard-details {
    padding: 10rem 0rem 10rem 10rem;
  }
  .iphones {
    margin: auto;
    text-align: center;
    width: 60%;
    display: block;
  }

  .onboard-details h3 {
    font-weight: 400;
    font-size: 2rem;
    text-align: left;
  }

  .onboard-details h5 {
    margin-left: 2rem;
    font-size: 2rem;
  }
}
