.nav-content {
  padding-top: 1rem;
}

.email-input {
  padding: 0.3rem;
}

.join-waitist-container {
  height: 4rem;
  margin: 1rem;
  margin-right: 2rem;
}

.right-float {
  float: right;
}

.background-fade-color {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1rem 3rem;
}

.ok-button {
  border: none;
  background-color: #0aa4b4;
  color: white;
  padding: 0.35rem 1rem;
}

.header-logo-image {
  height: 8rem;
  margin-left: 2rem;
}

.margin-for-nav {
  margin-right: 2rem;
  transform: translateY(-2.4rem);
}

@media only screen and (max-width: 767px) {
  .header-logo-image {
    /* position: absolute;
    top: 5px; */
    width: 100%;
    height: auto;
    margin-left: auto !important;
  }
  .nav-content {
    padding-top: 0rem;
  }
  .hiddenOnMobile {
    display: none;
  }
}

.inline-form {
  display: inline;
}

@media (min-width: 1900px) {
  .header-logo-image {
    height: 10rem;
    margin-left: 10vw !important;
  }
  .margin-for-nav {
    margin-right: 10vw !important;
  }

  .join-waitist-container {
    height: 8rem;
    margin: 1rem;
    margin-right: 10vw !important;
  }
}
