.trading-blurb {
  background-color: #1c1c1c;
  padding: 3rem 0rem;
}

.trading-blurb h5 {
  font-weight: 200;
  font-size: 2.6rem;
  color: #3e7b81;
}
