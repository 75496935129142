/* 
Need better quality image to scale dynamically
.logo-image{
    width: 60vw;
} */

.app-image {
  width: 70%;
  margin: auto;
}

.masthead {
  position: relative;
  display: block;
}

.resource-download-link {
  color: #75c1ca;
  text-decoration: none;
}

.resource-download-link:hover {
  color: white;
  text-decoration: none;
}

.resource-download-link:visited {
  color: #75c1ca;
  text-decoration: none;
}

.lima-button {
  color: #fff !important;
  background-color: #3197a1 !important;
  border-color: #3197a1 !important;
}

.lima-button:hover {
  color: #fff !important;
  background-color: #75c1ca !important;
  border-color: #75c1ca !important;
}

.margin-move {
  padding: 5rem 1rem 1rem 1rem;
  background-color: rgba(50, 50, 50, 0.75);
  margin-top: 8rem !important;
  margin-left: 14vw;
}

.padding-mobile {
  padding: 1rem;
}

@media (max-width: 768px) {
  .margin-move {
    margin-top: 14rem !important;
    margin-left: auto;
    padding: 0rem;
  }
}

@media (min-width: 1900px) {
  .margin-move {
    margin-top: 24rem !important;
  }
  .masthead h2 {
    font-size: 5rem;
  }
  .masthead h3 {
    font-size: 3rem;
  }
}
